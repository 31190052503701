import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withAuthenticationContext } from '../../Authenticate';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '../Typography';
import muiThemeColors from '../muiThemeColors';
import NavLink from '../NavLink';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '../IconButton';
import Avatar from '../Avatar';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = () => ({
    logo: {
        marginTop: 5,
        marginRight: 20,
    },
    spacer: {
        flexGrow: 1,
    },
});

const CustomAppBar = withStyles((theme) => ({
    root: {
        background: (props) => props.color,
        color: (props) => theme.palette.getContrastText(props.color),
    },
}))(({ color, ...rest }) => <AppBar {...rest} />);

class AppTitleBar extends React.Component {
    state = {
        profileMenuAnchor: null,
    };

    openMenu = (evt) => {
        this.setState({ profileMenuAnchor: evt.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ profileMenuAnchor: null });
    };

    render() {
        if (!this.props.account) {
            // In theory the title bar is only rendered while the user is logged
            // in; however, there is a window during logout where the title bar
            // may be rendered without any authenticated user.  This is only
            // a transitional state before the login page is displayed.  Skip
            // rendering of the title bar to avoid any references to an
            // undefined account.
            return null;
        }

        return (
            <Toolbar>
                <div className={this.props.classes.logo}>
                    <NavLink to="/">
                        <img src={this.props.logoUrl} alt={this.props.appTitle} />
                    </NavLink>
                    <Button aria-label={this.props.visualAssistanceText}></Button>
                </div>
                <Typography variant="h1">{this.props.appTitle}</Typography>
                <div className={this.props.classes.spacer} />
                <Hidden xsDown>
                    <Typography>{this.props.account.name}</Typography>
                </Hidden>
                <IconButton onClick={this.openMenu}>
                    <Avatar>{this.props.account.name}</Avatar>
                </IconButton>
                <Popover
                    open={this.state.profileMenuAnchor !== null}
                    onClose={this.handleMenuClose}
                    anchorEl={this.state.profileMenuAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuList>
                        <MenuItem onClick={this.props.logout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </MenuList>
                </Popover>
            </Toolbar>
        );
    }
}

class TopBar extends React.Component {
    render() {
        const { classes, ...rest } = this.props;

        if (muiThemeColors.includes(this.props.color)) {
            return (
                <AppBar position="fixed" color={this.props.color} role="banner">
                    <AppTitleBar {...rest} classes={classes} />
                </AppBar>
            );
        } else {
            return (
                <CustomAppBar position="fixed" color={this.props.color} role="banner">
                    <AppTitleBar {...rest} classes={classes} />
                </CustomAppBar>
            );
        }
    }
}
TopBar.propTypes = {
    /**
     * Application Title
     */
    appTitle: PropTypes.string,
    /**
     * Aria Text - Screen Reader Only Instruction Text
     */
    visualAssistanceText: PropTypes.string,
    /**
     * Url of the logo
     */
    logoUrl: PropTypes.string,
    /**
     * background color of the app bar
     */
    color: PropTypes.string,
};

export default compose(withStyles(useStyles), withAuthenticationContext)(TopBar);
