import React from 'react';
import TopBar from '../TopBar';
import { resourceUrl } from '../../api';

class Banner extends React.Component {
    render() {
        const logoUrl = resourceUrl('siteInfo/logo');

        return (
            <TopBar
                appTitle={this.props.title}
                color="#f5f5f5"
                logoUrl={logoUrl}
                visualAssistanceText={this.props.visualAssistanceText}
            />
        );
    }
}

export default Banner;
